@import "styles/mixins.scss";

.carousel {
  display: flex;
  position: relative;
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;
  .content {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    gap: 16px;
    width: 100%;
  }
  .btn {
    font-weight: 600;
    line-height: 24px;
    font-size: 18px;
    display: flex;
    align-items: center;
    width: fit-content;
    height: 50px;
    padding: 10px 12px;
    border-radius: 12px;
    color: rgba(25, 0, 128, 1);
    border: none;
    background: none;
    cursor: pointer;
    svg {
      transition: ease 0.2s;
      transform: rotate(0deg);
      width: 24px;
      height: 24px;
    }
    &.currentRoute:not(.active) {
      @include gradientText;
    }
    &.active {
      color: rgba(255, 255, 255, 1);
      transition: background 0.3s ease;
      @include gradientBackground;
      svg {
        transform: rotate(180deg);
        transition: ease 0.2s;
      }
    }
    &.disabled {
      @include disabled;
    }
    &:not(.active, .disabled):hover {
      @include gradientText;
    }
  }

  .prev,
  .next {
    width: 50px;
    height: 50px;
    padding: 12px;
    border-radius: 50%;
    border: 1px solid #d4d3d7;
    background: #fff;
    box-shadow: 0px 4px 0px 0px #d4d3d7;

    svg {
      stroke: rgba(0, 0, 0, 0.9);
      width: 24px;
      height: 24px;
      &:active,
      &:hover {
        stroke: #30f;
      }
    }

    &:hover,
    &:active {
      color: #30f;
      border-color: #30f;
      box-shadow: 0px 4px 0px 0px #30f;
    }
    &:active {
      transition: 200ms;
      top: 4px;
      box-shadow: none;
    }
  }
}
@media only screen and (max-width: 800px) {
  .carousel {
    .prev,
    .next {
      width: 40px;
      height: 40px;
      padding: 8px;
      svg {
        width: 20px;
        height: 20px;
      }
    }
    .content {
      .btn {
        font-size: 16px;
      }
    }
  }
}
