.selector {
  display: flex;
  color: white;
  font-size: 16px;
  flex-shrink: 0;
  align-self: flex-start;
  background-color: #fff;
  .btn {
    border: 1px solid #d4d3d7;
    padding: 12px 24px;
    height: 38px;
    background-color: #fff;
    text-align: center;
    display: flex;
    align-items: center;
    cursor: pointer;
    flex-shrink: 0;
    color: rgba(0, 0, 0, 0.7);
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 160%;
    &:not(.active) {
      box-shadow: 0px 4px 0px #d4d3d7;
      &:hover {
        box-shadow: 0px 4px 0px #8d96b5;
        border-bottom: #8d96b5;
      }
    }
    &.active {
      height: 42px;
      background-color: rgba(31, 53, 255, 0.1);
      color: #3300ff;
      border: 1px solid #3300ff;
    }
  }

  .btn:first-of-type {
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
  }

  .btn:last-of-type {
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
  }
}
