@import "styles/mixins.scss";

.dropdown {
  display: flex;
  flex-direction: column;
  height: fit-content;
  .options {
    margin: 0;
    padding: 0;
    .option {
      list-style: none;
      cursor: pointer;
      max-width: inherit;
      overflow: hidden;
      font-size: 14px;
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      color: var(--click, #190080);
      padding: 4px 12px;
      &.currentRoute {
        text-decoration: underline;
      }
      &.disabled {
        @include disabled;
        &:hover {
          text-decoration: none;
        }
      }
      &:first-of-type {
        margin-top: 12px;
      }
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
