.modal {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.7);
  z-index: 10;

  .error {
    font-size: 2rem;
    color: white;
    width: 50%;
  }

  .close {
    position: absolute;
    right: 40px;
    top: 40px;
    width: 40px;
    height: 40px;
    opacity: 0.7;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }
    &:before,
    &:after {
      position: absolute;
      left: 15px;
      content: " ";
      height: 40px;
      width: 3px;
      background-color: white;
    }
    &:before {
      transform: rotate(45deg);
    }
    &:after {
      transform: rotate(-45deg);
    }
  }
}
