.top {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 80px;
  top: -16px;
  left: 0;
  z-index: 1;
}

.top .subtitle {
  font-family: "Montserrat";
  text-transform: uppercase;
  letter-spacing: 0.3em;
  font-weight: 700;
  font-size: 14px;
  line-height: 170%;
  color: rgba(0, 0, 0, 0.7);
}
@media only screen and (max-width: 620px) {
  .top {
    flex-direction: column;
    justify-content: flex-start;
    height: 110px;
  }
}
